<template>
    <comp-table ref="comp_table" title="数据库机构查阅明细" :columns="columns" :dataList="dataList" :dataBefore="onDataBefore" @on-reset="onReset">
        <template v-slot:search="data">
            <Input v-model="data.search.keyword" style="width: 250px" placeholder="关键词" />
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"

// class Req {
//     static get(url) {
//         return new Promise((resolve, reject) => {
//             const xhr = new XMLHttpRequest()

//             xhr.open("GET", "https://api.gateway.prod.liefengtech.com" + url, true)

//             xhr.setRequestHeader("Authorization", "b413d6a5-3fc4-4450-89ab-636ad3d99c4f")

//             xhr.onreadystatechange = function() {
//                 if (xhr.readyState === 4) {
//                     if (xhr.status === 200) {
//                         const data = JSON.parse(xhr.responseText)
//                         resolve(data.data || data.dataList)
//                     } else {
//                         reject(xhr.responseText)
//                     }
//                 }
//             }

//             xhr.send()
//         })
//     }
// }

export default {
    components: { CompTable },

    data() {
        return {
            columns: [
                {
                    title: "机构名称",
                    minWidth: 300,
                    render: (h, params) => this.$core.getUrlParam("on"),
                },
                {
                    title: "姓名",
                    key: "userName",
                    minWidth: 150,
                },
                {
                    title: "操作时间",
                    key: "operationTime",
                    minWidth: 150,
                },
            ],

            dataList: null,
        }
    },

    mounted() {
        this.getTableData()
    },

    methods: {
        getTableData() {
            Request.get(`/gateway/api/ck/SystemOperateLog/getFunctionRequestUser?orgCode=${this.$core.getUrlParam("o")}&functionName=${this.$core.getUrlParam("n")}&startDate=${this.$core.getUrlParam("s")}&endDate=${this.$core.getUrlParam("e")}`).then(res => {
                this.dataList = res
            })
        },

        // 监听数据前置事件
        onDataBefore(data) {
            this.$refs.comp_table.keyword(data.keyword)
        },
    },
}
</script>
<style lang="less"></style>
